import { Api } from "../api/Api";

export const FetchTestUsers = () => {
    const testuserfetch = async (userId) => {
        try {
          const response = await Api.get(`v1/gettestuser/${userId}`);
          return response; // Return the response directly
        } catch (error) {
          console.error("Error fetching test user:", error); // Log the error for debugging
          throw error; // Rethrow the error to be handled in the component
        }
      };

  return { testuserfetch};
};

