import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FetchTestUsers } from "../../Hooks/useTestUserFetch";

const FetchTestUser = () => {
    const { userId } = useParams(); // Extract userId from URL params
    const { testuserfetch } = FetchTestUsers();
    const [testusers, setTestUsers] = useState([]);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const res = await testuserfetch(userId);
                if (res.data) {
                    setTestUsers(res.data);
                }
            } catch (err) {
                setError(err.message);
            }
        };
        fetchUser();
    }, [userId]); 

    return (
        <div>
             {testusers ? (
                <pre>{JSON.stringify(testusers, null, 2)}</pre>
            ) : (
                <div>No user found</div> 
            )}
        </div>
    );
};

export default FetchTestUser;
